import api from '@/api/api'
import { DescontoCategoria } from '@/models/DescontoCategoria'
import { descontoCategoriaTransformer } from '@/utils/transformers'
import Swal from 'sweetalert2'
import { getField, updateField } from 'vuex-map-fields'

export const SET_STATE = 'SET_STATE'
export const BOOTSTRAP_PROFILE = 'BOOTSTRAP_PROFILE'
export const LOAD_PRODUCTS = 'LOAD_PRODUCTS'
export const LOAD_CATEGORIES = 'LOAD_CATEGORIES'
export const CLOSE = 'CLOSE'
export const SUBMIT = 'SUBMIT'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    products: [],
    categories: [],
    roles: [],
    isEditing: false,
    loading: true,
    dialog: false,
  }
}

const state = getDefaultState()

const getters = {
  getField,
  products: (state) => state.products,
  categories: (state) => state.categories,
}

const mutations = {
  updateField,
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [CLOSE] (state) {
    state.dialog = false
    state.form = new DescontoCategoria()
  },
}

const actions = {
  async [BOOTSTRAP_PROFILE] ({ dispatch, commit, state }, { id, unidade_negocio_id }) {
    commit(RESET_STATE)
    commit(SET_STATE, { loading: true, isEditing: false, dialog: true })
    const descontoCategoria = new DescontoCategoria()
    descontoCategoria.unidade_negocio_id = unidade_negocio_id
    dispatch('form/BOOTSTRAP_FORM_DETAIL', { model: descontoCategoria }, { root: true })
    await Promise.all([dispatch(LOAD_PRODUCTS), dispatch(LOAD_CATEGORIES)])
    if (id) {
      commit(SET_STATE, { isEditing: true })
      await api.getEntidade('descontos', id).then((response) => {
        dispatch('form/BOOTSTRAP_FORM_DETAIL', { model: new DescontoCategoria(descontoCategoriaTransformer(response.data)) }, { root: true })
      })
    }
    commit(SET_STATE, { loading: false })
  },
  async [LOAD_PRODUCTS] ({ dispatch, commit }) {
    await api.listEntidade('produto').then((products) => {
      commit(SET_STATE, { products })
    })
  },
  async [LOAD_CATEGORIES] ({ dispatch, commit }) {
    await api.listEntidade('categoria').then((categories) => {
      commit(SET_STATE, { categories })
    })
  },
  async [SUBMIT] ({ commit, dispatch, state, rootState }) {
    const form = { ...rootState.form.detail }
    if (form.produto_id === undefined) {
      form.produto_id = null
    }
    Swal.fire({
      title: 'Enviando dados!',
      timerProgressBar: true,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading()
      },
      didOpen: () => {
        (async () => {
          commit('SET_STATE', { loading: true })
          return state.isEditing
            ? await api.salvarEntidade(form, 'descontos', form.id)
            : await api.cadastrarEntidade(form, 'descontos')
        })().then(
          (data) => {
            Swal.fire({
              title: state.isEditing
                ? 'Configuração atualizada com sucesso!'
                : 'Configuração cadastrada com sucesso!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
            commit('SET_STATE', { loading: false, dialog: false })
            dispatch('descontosCategoria/BOOTSTRAP', { id: form.unidade_negocio_id }, { root: true })
          },
          (error) => {
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              text: error.errors ? error.errors : error.message,
            })
            commit('SET_STATE', { loading: false, dialog: false })
          },
        )
      },
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
